<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
    @show="getNotificationsByAccount"
  >
    <template #button-content>
      <feather-icon
        :badge="totalUnread"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
        v-b-tooltip="`Thông báo`"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Thông báo
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ totalUnread }} New
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link
        v-for="notification in notifications"
        :key="notification.id"
      >
        <b-media @click="onClickNotification(notification.id)">
          <template #aside>
            <b-avatar
              size="32"
              :src="notification.avatar"
              :text="notification.avatar"
            />
          </template>
          <p
            class="media-heading text-truncate mw-notification-text"
            :class="{'text-muted': notification.status === 2}"
          >
            <span class="font-weight-bolder">
              {{ notification.createdBy }}: {{ notification.name }}
            </span>
          </p>
          <p
            class="mb-0 text-truncate mw-notification-text"
            :class="{'text-muted': notification.status === 2}"
          >
            {{ notification.description }}
          </p>
          <small class="notification-text">{{ notification.createdAt }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <b-overlay
      variant="white"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
      no-wrap
      :show="isLoading"
    />
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BOverlay, VBTooltip,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { computed, onMounted, ref } from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import { isUserLoggedIn } from '@/auth/utils'

export default {
  components: {
    BOverlay,
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  setup() {
    const isLoading = ref(false)
    const toast = useToast()
    const totalUnread = computed(() => store.getters['notification/totalUnread'])
    const notifications = computed(() => store.getters['notification/notificationsByAccount'])

    onMounted(async () => {
      if (isUserLoggedIn()) {
        await store.dispatch('notification/countNotificationsUnreadByAccount')
      }
    })

    const getNotificationsByAccount = async () => {
      isLoading.value = true
      try {
        await store.dispatch('notification/getNotificationsByAccount')
      } catch (e) {
        toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        isLoading.value = false
      }
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const onClickNotification = async id => {
      isLoading.value = true
      try {
        const response = await store.dispatch('notification/updateNotificationReceiverRead', id)
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            await store.dispatch('notification/countNotificationsUnreadByAccount')
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        isLoading.value = false
      }
    }

    return {
      isLoading,
      notifications,
      totalUnread,
      perfectScrollbarSettings,
      getNotificationsByAccount,
      onClickNotification,
    }
  },
}
</script>

<style>
.mw-notification-text {
  max-width: 25rem;
}
</style>
