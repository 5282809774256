export const Flag = Object.freeze({
  ACTIVE: 1,
  DEACTIVATE: 0,
  NOT_DELETED: 0,
  CREDIT_CLASS_STUDYING: 3,
  LOCK_SCORE: 1,
  UNLOCK_SCORE: 0,
  IN_PROGRESS_CHECK_EXAMINATION: 1,
})

export default { Flag }
